/* eslint-disable no-mixed-spaces-and-tabs */
import React, {useEffect, useState,} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {useStyles} from "./index.styles";
import _ from "lodash";
import apiClient from "../../../../../auth/apiClient";
import CallCenterService from "../../../../../services/CallCenterService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-duotone-svg-icons";
import useCancellationToken from "../../../../../hooks/useCancellationToken";
import {useEnqueueSnackbar} from "../../../../../hooks/useEnqueueSnackbar";
import {Typography} from "@material-ui/core";

const LPRImage = ({lprID, lprPlateText, forCaptureImage}) => {
  const classes = useStyles();
  const [capitalizedText, setCapitalizedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageContext, setImageContext] = useState(null);
  const [imageCapture, setImageCapture] = useState(null);
  const callCenterService = new CallCenterService(apiClient);
  const enqueueSnackbar = useEnqueueSnackbar();

  useEffect(() => {
    if (forCaptureImage && imageCapture != null) {
      return;
    }
    if (!forCaptureImage && imageContext != null) {
      return;
    }
    executeImageFetch();
  }, [lprID, forCaptureImage]);

  useEffect(() => {
    if (!_.isString(lprPlateText)) {
      setCapitalizedText(lprPlateText);
    } else {
      setCapitalizedText(lprPlateText[0]?.toUpperCase() + lprPlateText?.slice(1));
    }
  }, [lprPlateText]);

  const fetchLPRImage = async () => {
    setLoading(true);
    try {
      const response = forCaptureImage
        ? await callCenterService.getLicensePlateCaptureImage(lprID)
        : await callCenterService.getLicensePlateContextImage(lprID)
      ;
      const r = new FileReader();
      r.readAsDataURL(response.data);
      r.onload = () => {
        forCaptureImage ? setImageCapture(r.result) : setImageContext(r.result);
      };
    } catch (err) {
      forCaptureImage ? setImageCapture(null) : setImageContext(null);
    } finally {
      setLoading(false);
    }
  };

  const {execute: executeImageFetch} = useCancellationToken({
    func: fetchLPRImage,
    errHandleFunc: () => {
      setLoading(false);
      enqueueSnackbar("Failed to load image", {
        variant: "error",
        tag: "FailedToLoadLPRImage",
      });
    },
  });

  const imageData = forCaptureImage ? imageCapture : imageContext;
  return (
    <>
      {loading ? (
        <div className={clsx(["loading", classes.imageLoading])}>
          <FontAwesomeIcon icon={faSpinnerThird} spin size="5x"/>
        </div>
      ) : (
        <>
          {imageData ? (
            <div className={clsx(["image-wrapper", classes.imageWrapper])}>
              <>
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                <img src={imageData}
                     className={clsx(["image", classes.image])}
                     alt={`${capitalizedText} LPR Image`}
                     title={`${capitalizedText} LPR Image`}
                     data-image-is-capture={forCaptureImage}
                     data-lpr-id={lprID}
                     data-plate-text={lprPlateText}/>
              </>
            </div>
          ) : (
            <Typography align="center">Failed to load image</Typography>
          )}
        </>
      )}
    </>
  );
};
LPRImage.defaultProps = {
  lprID: null,
  lprPlateText: null,
  forCaptureImage: false
};
LPRImage.propTypes = {
  lprID: PropTypes.number,
  lprPlateText: PropTypes.string,
  forCaptureImage: PropTypes.bool
};

export default LPRImage;
