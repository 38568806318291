import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "4em",
    paddingBottom: "0.5em",
    paddingTop: "0.5em",
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  name: {
    display: "flex",
  },
  totalRow: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "4em",
    paddingBottom: "0.5em",
    paddingTop: "1.5em",
    borderTop: 'solid',
    "& .MuiTypography-h5": {
      fontWeight: "bold"
    },
  },
}));