import React from "react";
import {Button} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import {useInventoryDrawerFormContext} from "../InventoryDrawerFormProvider";

const SaveButtons = ({onClose}) => {
  const {state, actions: {saveClick}} = useInventoryDrawerFormContext();

  return (
    <>
      <Button
        style={{float: "right"}}
        startIcon={<CancelIcon/>}
        data-id="cancelBtn"
        name="cancel"
        variant="contained"
        onClick={onClose}
      >
        Cancel
      </Button>

      <Button
        style={{float: "right", marginRight: "1rem"}}
        startIcon={<SaveIcon/>}
        color="primary"
        data-id="saveBtn"
        data-testid="saveBtn"
        name="Save"
        variant="contained"
        disabled={!state.canSave}
        onClick={saveClick}
      >
        Save
      </Button>
    </>
  );
};

export default SaveButtons;
