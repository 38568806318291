import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  chartGrid: {
    // border: "1px solid black",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      order: 1,
    },
  },
  tableGrid: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  reportText: {
    width: "100%",
    textAlign: "center",
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      order: 3,
    },
  },
  pointer: {
    cursor: "pointer",
  },
}));
