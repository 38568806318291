import React, {
    createContext,
    useContext,
    useState,
    useCallback
} from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

export const RevenuePollingEnum = {
    SHOW: (duration)=> duration,
    HIDE: 0, // Hide Revenue Widget
    IGNORE: -1, // New Functionality
    REALTIME: -2, // Realtime
};

const revenuePollingFlagContext = createContext();

export const useRevenuePollingFlagContext = () => {
    const context = useContext(revenuePollingFlagContext);
    return context;
}

export const RevenuePollingFlagProvider = ({children}) => {
    const { revenueDashboardPolling } = useFlags();
    const revenuePollingFlag = revenueDashboardPolling ?? RevenuePollingEnum.IGNORE;

    const [accordianExpanded, setAccordianExpanded] = useState(false);

    const onAccordianClick = () => {
        setAccordianExpanded(prevState => !prevState);
      };

    const hideRevWidget = useCallback(() => revenuePollingFlag === RevenuePollingEnum.HIDE, [revenuePollingFlag]);

    const showTimer = useCallback(() => revenuePollingFlag !== RevenuePollingEnum.HIDE && revenuePollingFlag !== RevenuePollingEnum.IGNORE , [revenuePollingFlag]);

    return (
        <revenuePollingFlagContext.Provider value={{
            revenuePollingFlag,
            accordianExpanded,
            onAccordianClick, 
            showTimer, 
            hideRevWidget
        }}>
            {children}
        </revenuePollingFlagContext.Provider>
    )
}