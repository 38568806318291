import React, { useState } from "react";
import {
  TextField,
  Switch,
  Tooltip,
  FormControlLabel,
  Grid,
  InputAdornment,
} from "@material-ui/core";

const ConvenienceFeeInput = ({
  fieldError,
  existingFee,
  feeChanged,
  SaveEntitySetting,
  toggleEnable,
  enabledToggle,
  toggleCharge,
  chargeOnZeroToggle,
  mobilePayEnabled,
  toggleMobileFeeEnabled,
}) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid
          data-tag="mobilePaySwitch"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <FormControlLabel
            labelPlacement="end"
            data-id="enableMobilePaySwitch"
            data-testid="enableMobilePaySwitch"
            control={
              <Switch
                checked={mobilePayEnabled}
                data-checked={mobilePayEnabled}
                onChange={toggleMobileFeeEnabled}
                color="primary"
              />
            }
            label="Enable Mobile Pay"
          />
        </Grid>
        <Grid
          data-tag="conveneienceFee"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <FormControlLabel
            labelPlacement="end"
            disabled={!mobilePayEnabled}
            data-id="enableConvFeeSwitch"
            data-testid="enableConvFeeSwitch"
            control={
              <Switch
                checked={enabledToggle}
                data-checked={enabledToggle}
                onChange={toggleEnable}
                color="primary"
              />
            }
            label="Enable Convenience Fee"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <TextField
            error={fieldError}
            helperText={fieldError && `Must be a valid number between 0 and 10000.`}
            variant="outlined"
            type="text"
            data-id="convFeeTextField"
            name={"convFeeTextField"}
            fullWidth
            value={existingFee}
            disabled={!mobilePayEnabled ? true : !enabledToggle}
            label="mobile pay convenience fee"
            onChange={(e) => feeChanged(e.target.value)}
            onBlur={(e) =>
              SaveEntitySetting({
                name: "setting.conveniencefee",
                value: e.target.value,
              })
            }
            InputProps={{
              inputProps: {
                "data-testid": "convFeeTextField",
                "aria-label": "convFeeTextField",
              },
              inputMode: "numeric",
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <Tooltip title="This allows a convenience fee to be charged on a $0.00 transaction">
            <FormControlLabel
              labelPlacement="end"
              disabled={!mobilePayEnabled ? true : !enabledToggle}
              style={{ marginLeft: ".5%", marginBottom: "2%" }}
              data-id="chargeOnZeroSwitch"
              data-testid="chargeOnZeroSwitch"
              control={
                <Switch
                  checked={chargeOnZeroToggle}
                  data-checked={chargeOnZeroToggle}
                  onChange={toggleCharge}
                  size="small"
                  color="primary"
                />
              }
              label="Charge On $0"
            />
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

ConvenienceFeeInput.defaultProps = {
  existingFee: 0.0,
  enabledToggle: false,
  chargeOnZeroToggle: false,
  mobilePayEnabled: false,
};

export default ConvenienceFeeInput;
