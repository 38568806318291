/* eslint-disable no-mixed-spaces-and-tabs */
import React, {useEffect, useState,} from "react";
import PropTypes from "prop-types";


import clsx from "clsx";
import {useStyles} from "./index.styles";
import LPRImageDialog from "../../LPRImageDialog";
import _ from "lodash";

import VisibilityIcon from '@material-ui/icons/Visibility';

const LPREye = ({lprID, lprPlateText}) => {
  const classes = useStyles();
  const [toOpenDialog, setToOpenDialog] = useState(false);

  return (
    <>
			<span>
				<VisibilityIcon
          onClick={() => setToOpenDialog(true)}
          fontSize="large"
          className={clsx(classes.icon, "eyeIcon")}
          test-id="eyeIcon"
        />
			</span>

      <LPRImageDialog
        lprID={lprID}
        lprPlateText={lprPlateText}
        toOpen={toOpenDialog}
        onClose={(e) => {
          setToOpenDialog(false);
        }}/>
    </>
  );
};
LPREye.defaultProps = {
  lprID: null,
  lprPlateText: null
};
LPREye.propTypes = {
  lprID: PropTypes.number,
  lprPlateText: PropTypes.string
};
export default LPREye;
