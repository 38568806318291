import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ConfirmDialog = props => {
  const { isDeleting, handleConfirm, handleCancel } = props;
  return (
    <Dialog open={true} onClose={handleCancel} style={{ zIndex: 1000000 }}>
      <DialogTitle>
        {isDeleting ? "Delete Event" : "Cancel Changes"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isDeleting
            ? "Are you sure you want to delete this event?"
            : "Are you sure you want to discard your changes?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          {isDeleting ? "Delete" : "Discard"}
        </Button>
        <Button onClick={handleCancel} color="secondary" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
