/* eslint-disable no-mixed-spaces-and-tabs */
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  errorTxt: {
    color: theme.palette.error?.main,
    maxWidth: "200px",
  },
  reasonBox: {
    marginTop: theme.spacing(2),
  },
  dropdownForm: {
    minWidth: "7rem",
  },
}));
