/* eslint-disable no-mixed-spaces-and-tabs */
import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
	image: {
		maxWidth: "85vw",
		maxHeight: "80vh",
		border: "1px solid",
		borderColor: theme.palette.text.disabled,
		borderRadius: 3,
		boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",

	},
	imageWrapper: {
		alignContent: "center",
		textAlign: "center",
		justifyContent: "center",
		display: "block",
		margin: "0 auto"
	},
	imageLoading: {
		textAlign: "center",
		padding: 10,
		margin: 10,
		marginTop: "25vh",
		fontSize: "1.5rem",
		color: theme.palette.amano.base.primary.light,
	}
}));
