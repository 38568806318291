import { Accordion, AccordionDetails, AccordionSummary, Box, Tab, Tabs, Typography, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import Title from "../../../../Title";
import OverviewTab from "./OverviewTab/OverviewTab.index";

import { useStyles } from "./FacilityGroupDisplayStyles";
import FacilityDetails from "../FacilityDetails/FacilityDetails.index";
import {useRevenueDataContext} from "../RevenueDataProvider";
import { useRevenuePollingFlagContext } from "../useRevenueDashboardPollingContext";
import { useRevenuePollingTimer } from "../RevenueSyncTimer/useRevenuePollingTimer";

import Currency from "../../../../Currency";

export default function FacilityGroupDisplay() {
  // local state, ui controls
	const [selectedGroup, setSelectedGroup] = useState(0);
  const classes = useStyles();

	const handleTabChange = (event, newValue) => {
		setSelectedGroup(newValue);
	};

  // redux state
  const facilityList = useSelector(
    (state) => state.entityScope?.selected, shallowEqual
  ) ?? [];

  const {
    revenueByFacility,
    totalRevenue,
    facilities,
    getTotalsData
  } = useRevenueDataContext();

  const {onAccordianClick, accordianExpanded, revenuePollingFlag} = useRevenuePollingFlagContext()

  useRevenuePollingTimer(accordianExpanded, getTotalsData, facilities, revenuePollingFlag)

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    if (value !== index) {
        return <></>;
    }

    return (
    <div
      role="tabpanel"
      id={`revenue-tabpanel-${index}`}
      aria-labelledby={`revenue-tab-${index}`}
      {...other}
    >
      <Box p={3} style={{ padding: '0px' }}>
        {children}
      </Box>
    </div>
    );
  }

  const getSelectedRevenue = () => {
    if (selectedGroup === 0 ) {
      return "";
    }

    let value = revenueByFacility.find(x => x.parentEntityID === facilityList[selectedGroup-1].id)?.net;
    if (isNaN(value)) value = 0;

    return (
    <Typography variant="h5" color="primary">
        <Currency value={value}></Currency>
    </Typography>);
  };


  return (
    <Accordion 
      expanded={accordianExpanded} 
      className={clsx("revenue-panel", classes.root)}
      data-testid="revenue-widget-fc"
      onChange={onAccordianClick}
      >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon className={clsx("expand-revenue", "button")} />
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Title cssSelector="title" data-id="titleCard">
          Revenue
        </Title>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'inherit'}}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <div className={classes.selectedRevenue}>
              {getSelectedRevenue()}
            </div>
            <Tabs 
              className={classes.tabs} 
              value={selectedGroup}
              onChange={handleTabChange}
              variant="scrollable"
              textColor="primary"
              indicatorColor="primary"
              scrollButtons="auto">
                <Tab label="Today's Net Revenue" value={0}/>
                {facilityList.map((facility, index) => 
                  <Tab label={facility.name} key={`${facility.name}-tab`} value={index + 1} data-id={`${facility.id}_Tab`}/>
                )}
              </Tabs>
              <Divider />
              <TabPanel value={selectedGroup} index={0}>
                <OverviewTab facilityList={facilityList} revenueByFacility={revenueByFacility} totalRevenue={totalRevenue} />
              </TabPanel>
              {facilityList.map((facility, index) => 
                <TabPanel index={index + 1} value={selectedGroup} key={`${facility.name}-content`}>
                  <FacilityDetails facilityID={facility.id} />
                </TabPanel>
              )}
            </Box>
          </Box>
      </AccordionDetails>
    </Accordion>
  );
}