/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState, } from "react";
import { useSelector, shallowEqual } from "react-redux";
import clsx from "clsx";
import moment from "moment-timezone";
import {
  Grid,
  Typography,
  Divider,
  Button,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core";

import SelectableChip from "../../../SelectableChip";

import { useStyles } from "./styles";
import useCurrentFacilityTimezone from "../../../../hooks/useCurrentFacilityTimezone";

const CredentialInventorySearch = ({ onSearch, loading }) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState(1);
  const [selectedContext, setSelectedContext] = useState(0);
  const [selectedAge, setSelectedAge] = useState(0);
  const {timeZone} = useCurrentFacilityTimezone();

  const setSearch = () => {
    let date;

    switch (selectedAge) {
      case (1):
        date = moment().tz(timeZone).startOf('day');
        break;
      case (2):
        date = moment().tz(timeZone).startOf('day').subtract(1, 'week');
        break;
      case (3):
        date = moment().tz(timeZone).startOf('day').subtract(1, 'month');
        break;
      case (4):
        date = moment().tz(timeZone).startOf('day').subtract(1, 'year');
        break;
      default:
        date = null;
    }
    date = date?.tz('UTC').format("MM/DD/YYYY hh:mm:ss A").toString();

    onSearch({
      selectedType: selectedType,
      date: date,
      searchTerm: searchTerm,
      selectedContext: selectedContext
    });
  }

  return (
    <>
      <Grid container item xs={12} md={6}>
        <Grid container item xs={12} md={12} className={clsx([classes.sBlock, "type-container"])}>
          <Grid item xs={2} md={2}>
            <Typography className={clsx(["type-title",])}>Type</Typography>
          </Grid>
          <Grid item xs={10} md={10}>
            <SelectableChip
              text="Contract"
              className={clsx(["type-option", "contract"])}
              variant="primary"
              onClick={() => setSelectedType(0)}
              selected={selectedType === 0}
            />
            <SelectableChip
              text="Ticket"
              className={clsx(["type-option", "ticket"])}
              variant="primary"
              onClick={() => {
                setSelectedType(1);
                if (selectedContext == 1) {
                  setSelectedContext(0)
                }
              }}
              selected={selectedType === 1}
            />
            <SelectableChip
              text="Pre-Paid"
              className={clsx(["type-option", "prepaid"])}
              variant="primary"
              onClick={() => setSelectedType(2)}
              selected={selectedType === 2}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12} className={clsx([classes.sBlock, "age-container"])}>
          <Grid item xs={2} md={2}>
            <Typography className={clsx(["age-title",])}>Age</Typography>
          </Grid>
          <Grid item xs={10} md={10}>
            <SelectableChip
              text="All"
              size="small"
              variant="secondary"
              onClick={() => setSelectedAge(0)}
              selected={selectedAge == 0}
              title="all entries, regardless of age"
            />
            <span className={clsx([classes.olderThanWrapper])}>or older than:</span>
            <Divider orientation="vertical" flexItem />
            <SelectableChip
              text="Today"
              size="small"
              variant="secondary"
              onClick={() => setSelectedAge(1)}
              selected={selectedAge == 1}
              title="entries older than today"
            />
            <SelectableChip
              text="A Week"
              size="small"
              variant="secondary"
              onClick={() => setSelectedAge(2)}
              selected={selectedAge == 2}
              title="entries older than a week"
            />
            <SelectableChip
              text="A Month"
              size="small"
              variant="secondary"
              onClick={() => setSelectedAge(3)}
              selected={selectedAge == 3}
              title="entries older than a month"
            />
            <SelectableChip
              text="A Year"
              size="small"
              variant="secondary"
              onClick={() => setSelectedAge(4)}
              selected={selectedAge == 4}
              title="entries older than a year"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={6} className={clsx(["search-params-container"])}>
        <Grid container item xs={12} md={12} className={clsx([classes.sBlock, "search-criteria-container"])}>
          <Grid item xs={2} md={2}>
            <Typography className={clsx(["search-criteria-title"])}>Find</Typography>
          </Grid>
          <Grid item xs={10} md={10}>
            <TextField
              className={clsx([classes.notFatInput, "search-term"])}
              id="search-term"
              size="small"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => { setSearchTerm(e.target.value); }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12} className={clsx([classes.sBlock, "search-filter-container"])}>
          <Grid item xs={2} md={2} >
            <Typography className={clsx(["search-filter-title"])}>In</Typography>
          </Grid>
          <Grid item xs={10} md={10} className={clsx([])}>
            <div className={classes.filterType}>
              <Select className={clsx([classes.notFatSelect, "filter-type"])}
                variant="outlined"
                size="small"
                id="filter-type"
                data-testid="filter-select"
                value={selectedContext}
                onChange={(e) => { setSelectedContext(e.target.value); }}
              >
                <MenuItem data-value={0} className={clsx(["filter-type-option", "credentials"])} value={0}>Credentials</MenuItem>
                <MenuItem data-value={1} className={clsx(["filter-type-option", "names"])} disabled={selectedType === 1} value={1}>Names</MenuItem>
                <MenuItem data-value={2} className={clsx(["filter-type-option", "license-plates"])} value={2}>License Plates</MenuItem>
              </Select>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} className={clsx([classes.searchButtonRow])}>
        <Button
          disabled={loading || (selectedAge === 0 && !searchTerm)}
          title={(selectedAge === 0 && !searchTerm) ? "Search term required for searching across ALL" : ""}
          color="primary"
          className={clsx(["button", "search", classes.searchButton])}
          variant="contained"
          onClick={() => setSearch()}>Search</Button>
      </Grid>
    </>
  );
};

export default CredentialInventorySearch;
