// File: user/provider.js
import React, {useContext, useEffect, useState} from 'react';
import {createContext} from 'react';

export const InventoryDrawerFormContext = createContext({state: {}, actions: {}});
const InventoryDrawerFormProvider = ({children}) => {
  const [saveClicked, setSaveClicked] = useState(0);
  const [defaultStatus, setDefaultStatus] = useState(undefined);
  const [newStatus, setNewStatus] = useState(undefined);
  const [reason, setReason] = useState("");
	const [valid, setValid] = useState(false);
	const [saveSuccessful, setSaveSuccessful] = useState(0);
  const hasChanged = defaultStatus !== undefined &&
    newStatus !== undefined &&
    newStatus.length > 0 &&
    defaultStatus?.toLowerCase() !== newStatus?.toLowerCase()

  const value = {
    state: {
      changed: hasChanged,
      canSave: hasChanged && valid,
      saveClicked,
      status: newStatus,
      defaultStatus: defaultStatus,
      reason: reason,
			valid: valid,
			saveSuccessful: saveSuccessful,
    },
    actions: {
      setReason,
      setValid,
      setNewStatus,
      resetForm: () => {
        setNewStatus(undefined);
        setDefaultStatus(undefined);
        setReason("");
        setValid(false);
        setSaveClicked(0);
      },
      setDefaultStatus: (sts) => {
        setNewStatus(sts)
        setDefaultStatus(sts);
        setReason("")
      },
      saveClick: () => {
        setSaveClicked((oldValue) => oldValue + 1);
      },
      saveSuccess: (status) => {
        setSaveClicked(0);
        setDefaultStatus(status);
        setReason("");
				setSaveSuccessful(saveSuccessful+1);
      }
    },
  };
  return (
    <InventoryDrawerFormContext.Provider value={value}>
      {children}
    </InventoryDrawerFormContext.Provider>
  )
};
export default InventoryDrawerFormProvider;

export function useInventoryDrawerFormContext() {
  return useContext(InventoryDrawerFormContext);
}