import React from "react";
import { useStyles } from "./OverviewTabStyles";
import Currency from "../../../../../Currency";
import { Typography } from "@material-ui/core";

export default function OverviewTab({ facilityList, revenueByFacility, totalRevenue }) {
  const classes = useStyles();

  function getRevenue(facilityID) {
    return revenueByFacility?.find(x => x.parentEntityID == facilityID)?.net ?? 0;
  }

  return (
    <div>
      {facilityList?.map((facility) => 
        <>
            <div className={classes.row} key={`${facility.id}-overview`}>
                <div className={classes.name}>
                    <Typography variant="h5" color="primary">{facility.name}</Typography> 
                </div>
                <div>
                    <Typography variant="h5" color="primary">
                        <Currency value={getRevenue(facility.id)} />
                    </Typography>
                </div> 
            </div>
        </>
      )}
      <div className={classes.totalRow}>
        <div>
            <Typography color="primary" variant="h5">{"Today's Total"}</Typography>
        </div>
        <div>
            <Typography variant="h5" color="primary">
                <Currency value={totalRevenue}></Currency>
            </Typography>
        </div>
      </div>
    </div>
  );
}