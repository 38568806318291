/* eslint-disable no-mixed-spaces-and-tabs */
import React, {useEffect, useState,} from "react";
import PropTypes from "prop-types";


import {
	Avatar
} from "@material-ui/core";
import clsx from "clsx";
import {useStyles} from "./index.styles";
import LPRImageDialog from "../../LPRImageDialog";
import _ from "lodash";


const LPRRowImage = ({ lprID, lprPlateText, lprConfidence, enabled }) => {
  const classes = useStyles();
  const [toOpenDialog, setToOpenDialog] = useState(false);

  const handleOpen = (e) => {
		if(enabled){
			e.stopPropagation()
			//only get image when opening, otherwise we're doing it always.
			setToOpenDialog(true);
		}
  }

  const handleDialogClose = (e) => {
    setToOpenDialog(false);
  }

  return (
    <>
			<div className={clsx([classes.plateEntry, "plate-entry"])}>
				<Avatar className={clsx([classes.confidence])}>
					{`${lprConfidence}%`}
				</Avatar>
				<div className={clsx([classes.plateText, "plate-text", enabled && classes.clickableButton])}
					onClick={handleOpen}
					data-plate-text={lprPlateText}
					data-lpr-id={lprID}>
					{lprPlateText}
				</div>
			</div>

      <LPRImageDialog lprID={lprID} lprPlateText={lprPlateText} toOpen={toOpenDialog} onClose={handleDialogClose}/>
    </>
  );
};
LPRRowImage.defaultProps = {
  lprID: null,
  lprPlateText: null
};
LPRRowImage.propTypes = {
  lprID: PropTypes.number,
  lprPlateText: PropTypes.string
};
export default LPRRowImage;
