/* eslint-disable no-mixed-spaces-and-tabs */
import {darken, lighten, makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
	lpWrapper:{
		float:'right',
	},
	name:{
		verticalAlign: 'sub'
	},
	ageWrapper: {
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			marginLeft:0,
		},
		marginLeft:5,
		color: theme.palette.amano.base.secondary.main,
	},
	sBlock: {
		marginTop:2,
		marginBottom:10,
	},
	olderThanWrapper: {
		marginLeft: 15,
		fontStyle: "italic",
		color: theme.palette.text.disabled,
	},
	notFatInput: {
		backgroundColor: theme.palette.background.paper,
		width: "98%",
		'& .MuiOutlinedInput-inputMarginDense': {
			paddingBottom: 'none !important',
		},
	},
	searchButtonRow:{
		textAlign:"right",
	},
	searchButton: {
		float: "right",
		marginRight: 10,
		marginTop: 5
	},
	notFatSelect: {
		backgroundColor: theme.palette.background.paper,
		minWidth: 200,
		'& .MuiOutlinedInput-input': {
			paddingBottom: 'none !important',
			padding: '10px !important'
		}
	},
	filterType: {
		justifyContent:"flex-end",
		float:"right",
		marginRight:8,
	}
}));
