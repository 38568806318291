/* eslint-disable no-mixed-spaces-and-tabs */
import { darken, lighten, makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
	title: {
		color: theme.palette.amano.base.primary.main,
		"&.dontshow": {
			display: "none",
			visibility: "hidden",
		},
	},
	container: {
		display: "flex",
		alignItems: "center",
		justifyContent: "right",
		paddingRight: theme.spacing(2),
	},
	textBox: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.up("sm")]: {
			flexDirection: "row",
		},
	},
	dialogPlateText: {
		[theme.breakpoints.up("sm")]: {
			marginRight: "4px",
		},
	},
	removedImageWrapper: {
		alignContent: "center",
		textAlign: "center",
		justifyContent: "center",
		display: "block",
		margin: "0 auto"
	},
	titlePlateText: {
		float: "right",
		fontFamily: `"Lucida Console","Courier New","monospace"`,
		color: theme.palette.amano.base.secondary.main,
		borderColor: theme.palette.text.disabled,
		padding: 2,
		paddingLeft: 10,
		paddingRight: 10,
		backgroundColor: theme.palette.action.hover,
		boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.19)",
		borderRadius: 3,
		"&.dontshow": {
			display: "none",
			visibility: "hidden",
		},
	},
	thumbnailBar: {
		float: "left",
		display: "inline-block",
		"&.dontshow": {
			display: "none",
			visibility: "hidden",
		},
	},
	thumbnail: {
		display: "inline-block",
		border: "1px solid",
		borderColor: theme.palette.text.disabled,
		padding: 5,
		cursor: "pointer",
		marginRight: 10,
		"&.selected": { backgroundColor: theme.palette.amano.base.secondary.light, },
		"&:hover": { backgroundColor: lighten(theme.palette.amano.base.secondary.light, .3), },
	},
	imageRemoved: {
		marginTop: "25vh",
		fontSize: "1.5rem",
		//color: theme.palette.amano.base.secondary.main,
		color: darken(theme.palette.amano.warnings.fire, .3),
	},
	removedIcon: {
		marginTop: 10,
		marginBottom: 10,
	}

}));
