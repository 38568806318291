import React from "react";
import PropTypes from "prop-types";

const ColoredLine = ({ color, weight, className }) => {
  const getHeightFromWeight = (weight) => {
    switch (weight) {
      case "thick":
        return 2.5;
      case "skinny":
        return 0.5;
      case "normal":
        return 1;
    }
  };

  return (
    <hr
      className={className}
      style={{
        color: color,
        backgroundColor: color,
        height: getHeightFromWeight(weight),
        width: "100%",
      }}
    />
  );
};

ColoredLine.defaultProps = {
  color: "black",
  weight: "normal",
};

ColoredLine.propTypes = {
  color: PropTypes.string,
  weight: PropTypes.oneOf(["thick", "skinny", "normal"]),
};

export default ColoredLine;
