import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {useStyles} from "./styles";
import {
  Typography,
  Grid,
  Divider,
  Button,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import TicketDetails from "./TicketDetails";
import PrepaidDetails from "./PrepaidDetails";
import AccessHolderDetails from "./AccessHolderDetails";
import HistoryComponent from "./HistoryComponent";
import SaveButtons from "./Form/SaveButtons";
import {useInventoryDrawerFormContext} from "./Form/InventoryDrawerFormProvider";

const ConfirmDialog = ({handleConfirm, show, onClose}) => {
  return (
    <Dialog open={show} onClose={onClose} style={{zIndex: 1000000}} data-testid="confirm-dialog">
      <DialogTitle>Discard Changes</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to discard your changes?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Discard
        </Button>
        <Button onClick={onClose} color="secondary" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const CredentialInventoryDetailDrawer = ({credential, type, open, onClose}) => {
  const classes = useStyles();
	const { state: { canSave, saveSuccessful }, actions: { resetForm }} = useInventoryDrawerFormContext();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const drawerClose = (e) => {
    if (!canSave) {
			simpleDrawerClose();
      return;
    }
    setShowConfirmDialog(true);
  }
  const simpleDrawerClose = () => {
    resetForm();
    onClose();
  }

	useEffect(() => {
		if (saveSuccessful) {
			onClose(true);
		}
	}, [saveSuccessful]);

  return (
    <Drawer
      className={clsx("drawer", classes.drawer)}
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={drawerClose}
    >
      <div className={classes.cssGridContainer}>
        <div className={classes.cssGrid}>
          <div>
            <Typography
              variant="h5"
              color="primary"
              component="h2"
              className={clsx(classes.title)}
            >
              Inventory Detail
            </Typography>
          </div>
          <Divider style={{marginBottom: 15}}></Divider>
          <div>
            <div className={clsx(classes.detailContainer)}>
              {
                {
                  0: <AccessHolderDetails contextID={credential?.contextID}/>,
                  1: <TicketDetails contextID={credential?.contextID}/>,
                  2: <PrepaidDetails contextID={credential?.contextID}/>
                }[type] || "No Info"
              }
            </div>
            <div style={{marginTop: "2rem"}}>
              <HistoryComponent transactionId={credential?.transactionID}></HistoryComponent>
            </div>
          </div>
          <Divider></Divider>
          <div style={{paddingRight: "8px"}}>
            <SaveButtons onClose={simpleDrawerClose}></SaveButtons>
            <ConfirmDialog
              show={showConfirmDialog}
              handleConfirm={() => {
                setShowConfirmDialog(false);
                simpleDrawerClose();
              }}
              onClose={() => setShowConfirmDialog(false)}>
            </ConfirmDialog>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default CredentialInventoryDetailDrawer;
