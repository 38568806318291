import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  doughnutSection: {
    width: "45%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      order: 2,
    },
  },
  barChartSection: {
    width: "55%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      order: 1,
    },
  },
  summaryContent: {
    justifyContent: "space-between",
  },
  titleContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  todayTotal: {
    color: theme.palette.primary.main,
    fontSize: "2vw",
    lineHeight: "initial", /* Resetting line height */
    maxHeight: "150px", /* Set maximum line height */
    [theme.breakpoints.down("sm")]: {
      fontSize: "5vw", /* Adjusted value for smaller screens */
    },
  },
  progressBar: {
    width: "100%",
    opacity: 0.5,
  },
  "sync-countdown-lbl" : {
    display:"flex"
  }
}));
