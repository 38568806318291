/* eslint-disable no-mixed-spaces-and-tabs */
import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  historyTable: {
    marginLeft: "0 !important",
    marginTop: "0 !important",
    marginBottom: "0 !important"
  },
  location: {
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(.5)
  },
  time: {
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(.5),
		color: theme.palette.text.disabled
  },
  activityHeader: {
    textAlign: 'end !important'
  },
  activityColumn: {
    textAlign: 'end'
  },
	historyTitle: {
		color: theme.palette.amano.base.main,
		fontWeight: 400,
	},
  skeleton: {
    marginBottom: "2rem",
  }
}));
