import React from "react";

const Currency = ({ value }) => {
  return (
    <>
      {new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value)}
    </>
  );
};

export default Currency;
