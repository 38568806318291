import React, { useEffect } from "react";

/* Components */
import {
  Grid,
} from "@material-ui/core";
import TodaysTickets from "../../TodaysTickets/TodaysTickets";
import DailyRevenue from "../../DailyRevenue/DailyRevenue";

/* Style */
import { useStyles } from "./FacilityDetailsStyles";

/* Utilities */
import RevenueSummary from "../../RevenueSummary";
import { useRevenueDataContext } from "../RevenueDataProvider";

const FacilityDetails = ({facilityID}) => {
  const classes = useStyles();

  const {
    getRevenueDataForFacility,
    facilityDetailsData
  } = useRevenueDataContext();

  const revenueData = facilityDetailsData()?.facilities?.find(x => x.facilityID === facilityID)?.revenueData ?? {};

  useEffect(() => {
   getRevenueDataForFacility(facilityID);
  }, [facilityID]);

  return (
    <>
      <Grid container>
          <Grid item className={classes.revenueSummary}>
          <RevenueSummary
              totals={{
              net: {
                  today: revenueData?.combinedTotals?.netDailyTotal,
                  monthToDate: revenueData?.combinedTotals?.netMonthlyTotal,
              },
              gross: {
                  today: revenueData?.combinedTotals?.grossDailyTotal,
                  monthToDate: revenueData?.combinedTotals?.grossMonthlyTotal,
              },
              }}
          />
          </Grid>
          <Grid container>
          <Grid item className={classes.doughnutSection}>
              <TodaysTickets ticketData={revenueData?.tickets} />
          </Grid>
          <Grid item className={classes.barChartSection}>
              <DailyRevenue revenue={revenueData?.netOverall ?? []} />
          </Grid>
          </Grid>
      </Grid>
    </>
  );
};

export default FacilityDetails;
