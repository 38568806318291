import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { blue, orange } from "@material-ui/core/colors";
import { Typography, Grid, Link } from "@material-ui/core";
import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import clsx from "clsx";

const DailyRevenue = ({ revenue }) => {
  const classes = useStyles();
  const history = useHistory();
  const { hasPermissions } = useHasPermissions();
  const ReportPermission = hasPermissions(["SummaryTotals"]);
  let revLabels = [];
  let revNet = [];
  let revGross = [];

  const handleReportClick = () => {
    history.push(`/reports/SummaryTotals`);
  };

  const data = React.useMemo(() => {
    if (revenue?.length > 0) {
      revenue.forEach(row => {
        revLabels.push(new Date(row?.date).toDateString());
        revNet.push(row?.net < 0 ? 0 : row.net);
        revGross.push(row?.gross < 0 ? 0 : row.gross);
      });

      return {
        labels: revLabels,
        datasets: [
          {
            label: "Net",
            backgroundColor: blue[700],
            borderColor: blue[700],
            borderWidth: 1,
            hoverBackgroundColor: blue[700],
            hoverBorderColor: blue[700],
            data: revNet
          },
          {
            label: "Gross",
            backgroundColor: orange[600],
            borderColor: orange[600],
            borderWidth: 1,
            hoverBackgroundColor: orange[600],
            hoverBorderColor: orange[600],
            data: revGross
          }
        ]
      };
    }
    return {};
  }, [revenue]);

  const options = {
    scales: {
      xAxes: [
        {
          stacked: false
        }
      ],
      yAxes: [
        {
          stacked: false,
          ticks: {
            min: 0
          }
        }
      ]
    }
  };

  return (
    <Grid container className={clsx("daily-revenue")}>
      <Grid item xs={12} sm={12} md={12} lg={12} className={clsx("bar-chart")}>
        <Typography
          className={clsx("title", classes.title)}
          color="primary"
          data-id="title"
        >
          Daily Revenue
        </Typography>
        <Bar data={data} options={options} data-id="barChart" />
      </Grid>
      <Grid item xs={12} className={classes.reportText}>
        {ReportPermission && (
          <Link
            data-id="SummaryTotalsReport"
            onClick={handleReportClick}
            className={clsx("report-link", classes.pointer)}
          >
            Summary Totals Report
          </Link>
        )}
      </Grid>
    </Grid>
  );
};

DailyRevenue.propTypes = {
  revenue: PropTypes.arrayOf(
    PropTypes.shape({
      net: PropTypes.number,
      gross: PropTypes.number,
      date: PropTypes.string
    })
  )
};

export default DailyRevenue;
