import { useEffect } from "react";
import { RevenuePollingEnum as pollingEnum } from "../useRevenueDashboardPollingContext";


export const useRevenuePollingTimer = (accordianExpanded,getTotalsData,facilities,revenuePollingFlag,loadingDetails) => {

    useEffect(() => {      
      // If the accordion is not open, reset countdown and do nothing else
      if (!accordianExpanded || loadingDetails) {
        return;
      }

      if(revenuePollingFlag == pollingEnum.HIDE || revenuePollingFlag == pollingEnum.IGNORE || revenuePollingFlag == pollingEnum.REALTIME) return;
      
      const intervalId = setInterval(() => {
        getTotalsData(facilities);
      }, revenuePollingFlag * 1000);
    
      // Cleanup function to clear the intervals when the component unmounts or when accordion closes
      return () => {
        clearInterval(intervalId);
      };
      }, [accordianExpanded, loadingDetails, revenuePollingFlag, facilities]);
}