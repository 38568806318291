/* eslint-disable no-mixed-spaces-and-tabs */
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  detailTitle: {
    color: theme.palette.amano.base.main,
    fontWeight: 400,
    '&.thin': {
      fontWeight: 300,
    }
  },
  row: {
    marginBottom: theme.spacing(2),
  },
  rowValue: {
    textAlign: "right",
    float: "right",
  }
}));
