import React, { useState } from "react";
import PropTypes from "prop-types";
import SplitButton from "../../Buttons/SplitButton";
import { TextField } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";

const defaultOptions = [
  { id: "out", name: "OUT" },
  { id: "in", name: "IN" },
  { id: "neutral", name: "NEUTRAL" },
];

const CredentialDetailSchema = Yup.object().shape({
  status: Yup.string()
    .notOneOf(["Unset", "unset"], "Status must be set")
    .required("Status must be set"),
  reason: Yup.string()
    .required("A reason is required in order to change the status")
    .min(1),
});

const CredentialStatusChange = ({
  ["data-id"]: dataID,
  className,
  statusOptions,
  onSubmit,
  onChange,
  defaultSelected,
  disabled,
}) => {
  const classes = useStyles();
  const [statusChanged, setStatusChanged] = useState(false);
  const { setFieldValue, submitForm, errors, values, setErrors } = useFormik({
    initialValues: {},
    validationSchema: CredentialDetailSchema,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });
  const [internalSelected, setInternalSelected] = useState(defaultSelected);
  const enqueueSnackbar = useEnqueueSnackbar();

  function handleSubmit() {
    if (internalSelected === values.status) {
      enqueueSnackbar("This credential has already been set for this status", {
        variant: "warning", tag: "CredentialAlreadySetForStatus"
      });
      return;
    }
    setInternalSelected(values.status);
    onSubmit({ status: values.status, reason: values.reason });
    setStatusChanged(false);
    setFieldValue("reason", "");
  }

  const handleReasonChange = (e) => {
    setFieldValue("reason", e.target.value);
  };

  const handleStatusChange = (status) => {
    if (status?.toLowerCase() === internalSelected?.toLowerCase()) {
      setErrors();
      setStatusChanged(false);
    } else {
      setStatusChanged(true);
    }
    setFieldValue("status", status);
    onChange(status);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <SplitButton
        data-id={dataID ?? "status-split-btn"}
        data-testid="credential-split-button"
        data-name={values["status"]}
        className={classes.splitBtn}
        options={statusOptions}
        onSubmit={submitForm}
        onChange={handleStatusChange}
        defaultSelected={defaultSelected}
        disabled={disabled}
      />

      {statusChanged === true && (
        <TextField
          data-testid="reason-textbox"
          data-id="reason-textbox"
          className={classes.reasonBox}
          variant="outlined"
          placeholder="Reason"
          onChange={handleReasonChange}
          value={values.reason ?? ""}
        />
      )}
      <p className={classes.errorTxt}>{errors && errors["status"]}</p>
      <p className={classes.errorTxt}>{errors && errors["reason"]}</p>
    </div>
  );
};

CredentialStatusChange.defaultProps = {
  statusOptions: defaultOptions,
  onSubmit: () => {},
  onChange: () => {},
  disabled: false,
};

CredentialStatusChange.propTypes = {
  defaultSelected: PropTypes.string,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CredentialStatusChange;
