import React from "react";
import {Grid, Typography} from "@material-ui/core"
import PropTypes from "prop-types";
import clsx from "clsx";
import {useStyles} from "./styles";

const DetailsValueRow = ({title, thin, titleSize, valueSize, children}) => {
  const classes = useStyles();
  return <Grid container className={clsx([classes.row])}>
    <Grid item xs={titleSize ?? 4}>
      <Typography variant="h6" className={clsx([classes.detailTitle, thin ? "thin" : ""])}>
        {title}
      </Typography>
    </Grid>
    <Grid item xs={valueSize ?? 8} className={clsx([classes.rowValue])}>
      {children}
    </Grid>
  </Grid>
}

DetailsValueRow.defaultProps = {
  title: null,
  thin: false,
  children: null
};
DetailsValueRow.propTypes = {
  title: PropTypes.string,
  thin: PropTypes.bool,
  children: PropTypes.node
};

export default DetailsValueRow;
