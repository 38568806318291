import React, { useState, useEffect } from "react";
import { useStyles, StyledTableCell } from "./styles";
import { Container, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import LogService from "../../services/LogService";
import apiClient from "../../auth/apiClient";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import moment from "moment";
import FileDownloadButton from "../FileDownloadButton";
import clsx from "clsx";

const service = new LogService(apiClient);

export const DeviceLogs = ({ entityId }) => {
  const classes = useStyles();
  const [logs, setLogs] = useState([]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [isLoading, setIsLoading] = useState();

  const fetchLogs = async () => {
    setIsLoading(true);
    try {
      var result = await service.getLogs(entityId);
      setLogs(result?.data?.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp)));
    } catch (error) {
      enqueueSnackbar("Failed to retrieve logs for device", {
        variant: "error", tag: "FailedToRetrieveLogsForDevice"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadLog = async (key) => {
    try {
      var result = await service.downloadLog(key);
      return result;
    } catch (error) {
      enqueueSnackbar("Failed to download log for device", {
        variant: "error", tag: "FailedToDownloadLogForDevice"
      });
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [entityId]);

  return (
    <Container className={classes.tablecontainer}>
      <Table
        data-id={`device-logs-${entityId}`}
        className={clsx(classes.table, "logs-table")}
        aria-label="spanning table"
      >

        <TableBody>

          {isLoading ? (
            <>
              <TableRow>
                <StyledTableCell colSpan={12}>
                  <Skeleton />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={12}>
                  <Skeleton />
                </StyledTableCell>
              </TableRow>
            </>
          ) : (
            <>
            {logs.length === 0 ? (
              <><Typography className={clsx("no-logs-message")} style={{color:"silver", fontStyle:"italic", textAlign:"center"}}>No logs to display</Typography></>
            ) : (
              <div style={{height:250,width:"100%",overflowY:"auto", overflowX:"hidden"}}>
              {logs?.map((log) => (
                <TableRow
                  className={clsx("log-row")}
                  key={`log-${log.TimeStamp}`}
                  style={{ borderTopWidth: "0px" }}
                  data-timestamp={moment.utc(log.timeStamp)
                    .format("MM/DD/YYYY")}
                >
                  <StyledTableCell className={clsx("timestamp")}>
										{moment.utc(log.timeStamp)
                      .format("MM/DD/YYYY")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <FileDownloadButton
                      size="small"
											text="Download"
											downloadingText="Downloading"
                      color="primary"
                      entityId={entityId}
                      entityDate={log.timeStamp}
                      fileType="txt"
                      fileName={`log-${log.timeStamp}-${entityId}`}
                      downloadHandler={() => downloadLog(log.location)}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
              </div>
            )}
            </>
            )
          }
        </TableBody>
      </Table>

    </Container>
  );
};

export default DeviceLogs;
