/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import clsx from "clsx";
import moment from "moment-timezone";
import {
  Grid,
  Typography,
  Divider,
  Button,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '@material-ui/lab/Pagination';

import CredentialInventoryTable from "./CredentialInventoryTable";
import CredentialInventorySearch from "./CredentialInventorySearch";
import Title from "../../Title";

import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import useCancellationToken from "../../../hooks/useCancellationToken";

import apiClient from "../../../auth/apiClient";
import InventoryService from "../../../services/InventoryService";

import { useStyles } from "./styles";

const CredentialInventory = () => {
  const classes = useStyles();
  const pageSize = 10;

  const inventoryService = new InventoryService(apiClient);

  const enqueueSnackbar = useEnqueueSnackbar();
  const { facilityID } = useCurrentFacility();

  const [loading, setSearchLoading] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
  const [activeSearch, setActiveSearch] = useState();
  const [results, setSearchResults] = useState();

  const scopeList = useSelector(
    (state) => state.entityScope?.selected, shallowEqual
    ) ?? [];
  const facilityGroupId = useSelector((state) => state.entityScope?.facilityGroupId);

	const getSearch = async ({ cancelToken }) => {
		setSearchLoading(true);
		const offset = ((currentPage - 1) * pageSize);

    let entityIds = Array.prototype.map.call(scopeList, function(f) { return f.id }).join(',');

		const res =
      await inventoryService.getInventorySearch(
        facilityID,
        entityIds,
        facilityGroupId,
        activeSearch.selectedType,
        activeSearch.date,
        activeSearch.searchTerm,
        activeSearch.selectedContext,
        pageSize, offset,
        cancelToken
      );

		setSearchLoading(false);
		setSearchResults(res.data);
	}

	const { execute: executeSearchQuery } = useCancellationToken({
		func: getSearch,
		errHandleFunc: () => {
			setSearchLoading(false);
			enqueueSnackbar("Failed to load search", {
				variant: "error",
				tag: "FailedToLoadSearch",
			});
		},
	});


	const onSearch = (searchState) => {
		setActiveSearch(searchState);

		setCurrentPage(1);
	}

  useEffect(()=> {
    if (activeSearch) {
      executeSearchQuery();
    }
  }, [facilityID, currentPage, activeSearch])

  return (
  <Grid container className={clsx([classes.root,"root"])}>
    <Grid item xs={12} className={clsx([classes.header, "title"])}>
        <Title cssSelector={classes.title}>Current Inventory</Title>
    </Grid>
		<CredentialInventorySearch onSearch={onSearch} loading={loading}/>

    {/* loading indicator and no-results display */}

    <Grid container className={clsx(["information-container"])}>
      <Grid item xs={12}>
        {activeSearch && (
          loading ? (
            <div className={clsx(["loading",classes.skeletonWrapper])}>
              <Skeleton  className={classes.skeleton} animation="wave" />
              <Skeleton className={classes.skeleton} animation="wave" />
              <Skeleton className={classes.skeleton} animation="wave" />
            </div>
          ):(
            <>
            <Divider className={classes.resultsDivider}></Divider>
            {results?.collection.length === 0 && (
              <div className={clsx(["animate__animated","animate__flipInX","results","not-found",classes.noResults])}> {/* i wanna make this the container, not the span - for better margins */}
                <span className={clsx(["no-results-text"])}>No results found</span>
              </div>
            )}
            </>
          )
        )}
      </Grid>
    </Grid>
      <Grid
        container
        item
        spacing={2}
        xs={12}
        className={clsx(classes.tableContainer)}
      >
        {activeSearch && !loading && Math.ceil((results?.totalCount ?? 1) / pageSize) > 1 && (
          <Pagination
            item
            className={clsx(classes.pagination)}
            count={Math.ceil((results?.totalCount ?? 1) / pageSize)}
            onChange={(_, value) => setCurrentPage(value)}
            page={currentPage}
            shape="rounded"
            color="primary"
          />
        )}
        {(results?.collection?.length > 0 && !loading) && (
					<CredentialInventoryTable results={results} credentialType={activeSearch.selectedType} onSuccessfulStatusChange={() => executeSearchQuery()}/>
				)}
      </Grid>
    </Grid>
  );
};

export default CredentialInventory;
