import React from "react";

/* Components */
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import Title from "../../../../Title";

/* Style */
import { useStyles } from "./FacilityDisplayStyles";
import clsx from "clsx";

/* Icons */
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Currency from "../../../../Currency";
import SyncIcon from '@material-ui/icons/Sync';
/* Hooks */
import useCurrentFacility from "../../../../../hooks/useCurrentFacility";

/* Utilities */
import FacilityDetails from "../FacilityDetails/FacilityDetails.index";
import { useRevenueDataContext } from "../RevenueDataProvider";
import { useRevenuePollingFlagContext } from "../useRevenueDashboardPollingContext";
import { useRevenuePollingTimer } from "../RevenueSyncTimer/useRevenuePollingTimer";

const FacilityDisplay = () => {
  const classes = useStyles();
  const {
    loadingDetails,
    facilityDetailsData,
    getTotalsData,
    facilities
  } = useRevenueDataContext();

  const {onAccordianClick, accordianExpanded,revenuePollingFlag} = useRevenuePollingFlagContext()

  useRevenuePollingTimer(accordianExpanded, getTotalsData, facilities, revenuePollingFlag)

  const { facilityID } = useCurrentFacility();

  return (
    <>
      <Accordion
        expanded={accordianExpanded}
        className={clsx("revenue-panel")}
        data-testid="revenue-widget-v2"
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon className={clsx("expand-revenue", "button")} />
          }
          data-testid="revenue-expand-icon"
          aria-controls="panel1bh-content"
          onClick={onAccordianClick}
          id="panel1bh-header"
          classes={{ content: classes.summaryContent }}
        >
          <div className={classes.titleContainer}>
            <Title cssSelector="title" data-id="RevenueTitle">
              Revenue
            </Title>
            <div
              className={clsx(classes.todayRevenueContainer, "net-total")}
              data-testid="net-total-today"
            >
              <div className={classes.todayTotal}>
                <Currency value={facilityDetailsData()?.facilities?.find(x => x.facilityID == facilityID)?.revenueData.netToday ?? 0} />
              </div>
              <Typography>Today&apos;s Net</Typography>
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails>
          {loadingDetails ? (
            <LinearProgress
              data-testid="revenue-loading"
              className={classes.progressBar}
            />
          ) : (
            <div>
              <FacilityDetails facilityID={facilityID} />
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FacilityDisplay;