import React, {useEffect, useState} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";


import {Typography, Container} from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton";
import {useStyles} from "./styles";
import SquareChip from "../../../../SquareChip";
import LPREye from "../LPREye";
import DetailsValueRow from "../DetailsValueRow";

import useCancellationToken from "../../../../../hooks/useCancellationToken";
import useHasPermissions from "../../../../../hooks/useHasPermissions";
import { useEnqueueSnackbar } from "../../../../../hooks/useEnqueueSnackbar";
import InventoryService from "../../../../../services/InventoryService";

import apiClient from "../../../../../auth/apiClient";
import CredentialStatusChange from "../Form/CredentialStatusChange";
import { useInventoryDrawerFormContext } from "../Form/InventoryDrawerFormProvider";

const statusOptions = [
  {id: '1', name: "IN"},
  {id: '5', name: "VOID"}
];
const TicketDetails = ({contextID}) => {
  const classes = useStyles();
	const enqueueSnackbar = useEnqueueSnackbar();
	const { hasPermissions } = useHasPermissions();
  const inventoryService = new InventoryService(apiClient);
  const [loading, setLoading] = useState(false);
  const [ticketDetails, setTicketDetails] = useState(null);
  const {
    state: {canSave, saveClicked, status, reason},
    actions: {saveSuccess, setDefaultStatus}
  } = useInventoryDrawerFormContext();
	const inventoryEditPremissions = hasPermissions(["inventory.edit"])

	useEffect(() => {
    if (contextID) {
      executeDetailsQuery();
    }
  }, [contextID]);

	useEffect(() => {
    if (saveClicked && canSave) {
			executeUpdateQuery();
    }
  }, [saveClicked]);

  const getDetails = async ({cancelToken}) => {
		setLoading(true);
		const res = await inventoryService.getTicketDetails(contextID, cancelToken);
    setLoading(false);
		setTicketDetails(res.data);
    setDefaultStatus('1');
  }

  const {execute: executeDetailsQuery} = useCancellationToken({
    func: getDetails,
    errHandleFunc: () => {
      setLoading(false);
      enqueueSnackbar("Failed to load details", {
        variant: "error",
        tag: "FailedToLoadDetails",
      });
    },
  });


	const { execute: executeUpdateQuery } = useCancellationToken({
		func: async ({ cancelToken }) => {
			await inventoryService.updateTicketStatus(contextID, parseInt(status), reason, cancelToken)
			saveSuccess(status);
		},
		errHandleFunc: () => {
			enqueueSnackbar("Failed to update status", {
				variant: "error",
				tag: "FailedToUpdateStatus",
			});
		},
	});

  return <>
    {loading ?
      <Container>
        <Skeleton className={classes.skeleton} variant="rect" height="2rem" animation="wave"/>
        <Skeleton className={classes.skeleton} variant="rect" height="2rem" animation="wave"/>
        <Skeleton className={classes.skeleton} variant="rect" height="2rem" animation="wave"/>
      </Container>
      :
      <>
        {ticketDetails !== null && (
          <>
            <DetailsValueRow title="Status">
							{ inventoryEditPremissions ? (
								<CredentialStatusChange statusOptions={statusOptions} />
							):
								<Typography
									variant="h6"
									className={clsx(classes.centered)}
									data-value={"IN"}>
										IN
								</Typography>
							}

            </DetailsValueRow>
            <DetailsValueRow title="Ticket"
                             thin={true}><Typography>{ticketDetails?.ticketID}</Typography></DetailsValueRow>
            <DetailsValueRow title="Plate" thin={true}> <span>
							<SquareChip mode="warning" text={ticketDetails?.read ? ticketDetails.read : "No Plate Found"}/>
              {
                ticketDetails?.hasImages && <LPREye lprPlateText={ticketDetails.read} lprID={ticketDetails.lprId}/>
              }
              </span>
            </DetailsValueRow>
          </>
        )}
      </>
    }
  </>
    ;
};

TicketDetails.defaultProps = {
  contextID: null
};
TicketDetails.propTypes = {
  contextID: PropTypes.string
};

export default TicketDetails;
