import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {MenuItem, Select, TextField} from "@material-ui/core";
import {useStyles} from "./styles";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import {useInventoryDrawerFormContext} from "../InventoryDrawerFormProvider";

const defaultOptions = [
  {id: "out", name: "OUT"},
  {id: "in", name: "IN"},
  {id: "neutral", name: "NEUTRAL"},
];

const CredentialDetailSchema = Yup.object().shape({
  status: Yup.string()
    .notOneOf(["Unset", "unset"], "Status must be set")
    .required("Status must be set"),
  reason: Yup.string()
    .required("A reason is required in order to change the status")
    .min(1),
});

const CredentialStatusChange = ({statusOptions}) => {
  const classes = useStyles();
  const {
    state: {defaultStatus, changed, reason},
    actions: {setReason, setNewStatus, setValid}
  } = useInventoryDrawerFormContext();
  const [internalSelected, setInternalSelected] = useState(defaultStatus ?? "");

  useEffect(() => {
    if (changed) {
      ValidateValues(internalSelected, reason);
    }
  }, [changed, internalSelected, reason])

  useEffect(() => {
    setNewStatus(internalSelected)
  }, [internalSelected]);

  useEffect(() => {
    if (defaultStatus !== undefined) {
      setInternalSelected(defaultStatus)
    }
  }, [defaultStatus]);

  async function ValidateValues(sts, res) {
    try {
      const validatedResult = await CredentialDetailSchema.validate({
        status: sts,
        reason: res
      });
      setValid(true);
    } catch (e) {
      setValid(false);
    }
  }

  return (
    <div>
      <FormControl className={classes.dropdownForm}>
        <Select
          data-testid="credential-drop-down"
          value={internalSelected}
          onChange={(e) => setInternalSelected(e.target.value)}>
          {(statusOptions ?? defaultOptions)?.map((type, index) => {
            return (<MenuItem key={index} value={type.id}>
                {type.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {changed === true && (
        <div>
          <TextField
            data-testid="reason-textbox"
            data-id="reason-textbox"
            className={classes.reasonBox}
            variant="outlined"
            placeholder="Reason"
            onChange={(e) => setReason(e.target.value)}
            value={reason}
          />
        </div>
      )}
    </div>
  );
};

CredentialStatusChange.defaultProps = {
  statusOptions: defaultOptions
};

CredentialStatusChange.propTypes = {
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  )
};

export default CredentialStatusChange;
