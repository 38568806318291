import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
// BrowserQRCodeReader moved to @zxing/browser but it cannot stop scanning. Need to use @zxing/library.
// BrowserMultiFormatReader cannot be used for long time(around 45min) due to AO-15379.
import { BrowserQRCodeReader, BrowserMultiFormatReader, NotFoundException } from '@zxing/library'; 
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import {isUndefined} from "lodash";
import { Typography } from "@material-ui/core";

const CodeReader = ({ handleRead, handleError, isScanning, device, onChange, disabled, enableQROnly = false }) => {
  const classes = useStyles(); 
  const enqueueSnackbar = useEnqueueSnackbar();
  const [ isCodeFound, setIsCodeFound] = useState(false);
  const [ selectedVideoDevice, selectVideoDevice ] = useState ('');
  const [ videoInputDevices, setVideoInputDevices ] = useState([]);
  const [videoClassName, setVideoClassName] = useState(clsx(["overlay-focus", classes.overlayFocus]));
  const [codeReader] = useState(() => enableQROnly ? new BrowserQRCodeReader() : new BrowserMultiFormatReader());
  const [refreshTimer, setRefreshTimer] = useState();

  const configureDeviceList = () => {
    codeReader.listVideoInputDevices()
      .then((videoInputDevices) => {
        if (videoInputDevices.length >= 1) {
          setVideoInputDevices(videoInputDevices);
        }

        if (!device) {
          selectVideoDevice(videoInputDevices[0]);
        } else {
          selectVideoDevice(videoInputDevices.find(x => x.deviceId == device));
        }
        
      })
      .catch((err) => {
        enqueueSnackbar("Unable to get video devices", {
          variant: "error", tag: "UnableToGetVideoDevices",
        });
      });
  };

  const startCodeReader = () => {
    codeReader.reset();
    codeReader.decodeFromVideoDevice(selectedVideoDevice.deviceId, 'video',
      function handleDecode(result, error) {
        if(result && !isUndefined(result) && handleDecode.lastScannedValue !== result.getText()) {
          const scannedText = result.getText();
          if (typeof handleDecode.lastScannedValue === 'undefined') {
            handleDecode.lastScannedValue = scannedText;
          }
          setIsCodeFound(true);
          handleDecode.lastScannedValue = scannedText;
          handleRead(scannedText);
          setTimeout(async () => {
            setIsCodeFound(false);
          }, 50);
        }
  
        if (error && !(error instanceof NotFoundException)) {
          console.error(error);
        }
      })
      .then(res => {
        console.log('result', res);
      })
      .catch(err => {
        console.log('error', err);
        setIsCodeFound(false);
      });

      clearTimeout(refreshTimer);
      // Barcodes may not be read continuously without restarting every hour.
      setRefreshTimer(
      setTimeout(async () => {
        codeReader.reset();
        codeReader.stopAsyncDecode();
        codeReader.stopContinuousDecode();
        startCodeReader();
      }, 3600000));
  }
  
  const stopCodeReader = () => {
    clearTimeout(refreshTimer);
    codeReader.reset();
    codeReader.stopAsyncDecode();
    codeReader.stopContinuousDecode();
  };
  
  useEffect(() => {
    configureDeviceList();  
    return () => {
      stopCodeReader();
    };
  }, []);

  useEffect (() => {
    if (!isScanning || disabled) {
      stopCodeReader();
    } else if (selectedVideoDevice && isScanning) {
      startCodeReader();
    }
  }, [selectedVideoDevice, isScanning, disabled] );
  
  useEffect(() => {
    if (disabled)
      setVideoClassName(clsx(["overlay-focus", classes.overlayFocusDisabled]));
    else if (isCodeFound === true)
      setVideoClassName(clsx(["overlay-focus", classes.overlayFocus, classes.overlayFocusFlash]));
    else
      setVideoClassName(clsx(["overlay-focus", classes.overlayFocus]));
  }, [videoClassName, isCodeFound, disabled]);

  const changeDevice = (deviceName) => {
    selectVideoDevice(videoInputDevices.find(x => x.label == deviceName));
    if (onChange) onChange(videoInputDevices.find(x => x.label == deviceName).deviceId);
  }

  return (
    <div data-testid="video-div" disabled={disabled} className={clsx(["codereader-root", classes.root]) }>
      {(videoInputDevices.length === 0)
      ? ( 
        <Typography className={clsx("no-video-devices")} variant="overline" color="error">No video devices have been found.</Typography>
        )
      : (       
        <Select name="video-devices" data-id="video-devices" data-testid="video-devices" value={(selectedVideoDevice?.label ? selectedVideoDevice?.label : '') ?? ''} 
          onChange={(e) => {changeDevice(e.target.value)}}
          disabled={disabled}>
          {videoInputDevices.map((inputDevice) => (
            <MenuItem 
            key={`select-${inputDevice.deviceid}`} id={inputDevice.deviceid} value={inputDevice.label} >{inputDevice.label}</MenuItem>
          ))}        
        </Select>
      )}
      <div className={clsx(["video-content", classes.videoContent])}>
        <video id="video" disabled={disabled}  className={clsx(["video", classes.video])}/>
        { isScanning === true && disabled === false && (
            <>
              <div className={clsx(["overlay-message", classes.overlayMessage])}>Place Barcode In Orange Square</div>
              <div className={videoClassName} data-testid="video"/>
            </>
          )
        }
      </div>
    </div>
  );
};
  
export default CodeReader;
  