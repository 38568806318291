import React, { useState, useEffect } from "react";
import ConvenienceFeeInput from "./ConvenienceFeeInput";
import SettingsService from "../../services/SettingsService";
import apiClient from "../../auth/apiClient";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";

const settingsService = new SettingsService(apiClient);

const ConvenienceFee = ({ entityID }) => {
  const [mobilePayEnabled, setMobilePayEnabled] = useState(false);
  const [fee, setFee] = useState(0.0);
  const [chargeOnZeroToggle, setChargeOnZeroToggle] = useState(false);
  const [enabledToggle, setEnabledToggle] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const enqueueSnackbar = useEnqueueSnackbar();

  useEffect(() => {
    settingsService
      .getSettings(entityID)
      .then((result) => {
        setMobilePayEnabled(
          result.data["setting.mobilepay.enabled"] === undefined
            ? false
            : result.data["setting.mobilepay.enabled"]?.toLowerCase() === "true"
        );
        setFee(
          result.data["setting.conveniencefee"] === undefined
            ? 0.0
            : result.data["setting.conveniencefee"]
        );
        setEnabledToggle(
          result.data["setting.conveniencefee.enabled"] === undefined
            ? false
            : result.data["setting.conveniencefee.enabled"]?.toLowerCase() ===
                "true"
        );
        setChargeOnZeroToggle(
          result.data["setting.conveniencefee.chargeonzero"] === undefined
            ? false
            : result.data[
                "setting.conveniencefee.chargeonzero"
              ]?.toLowerCase() === "true"
        );
      })
      .catch((err) => {
        if (err.response == null || err.response.status != 404)
          enqueueSnackbar("Failed to retrieve convenience fee", {
            variant: "error",
            tag: "FailedToRetrieveConvenienceFee",
          });
      });
  }, [entityID]);

  const toggleMobileFeeEnabled = () => {
    setMobilePayEnabled(!mobilePayEnabled);
    SaveEntitySetting({
      name: "setting.mobilepay.enabled",
      value: !mobilePayEnabled,
    });
  };

  const toggleCharge = () => {
    setChargeOnZeroToggle(!chargeOnZeroToggle);
    SaveEntitySetting({
      name: "setting.conveniencefee.chargeonzero",
      value: !chargeOnZeroToggle,
    });
  };

  const toggleEnable = () => {
    setEnabledToggle(!enabledToggle);
    SaveEntitySetting({
      name: "setting.conveniencefee.enabled",
      value: !enabledToggle,
    });
  };

  const feeChanged = (fee) => {
    if (String(fee)[0] == ".") fee = "0" + String(fee);

    setFieldError(
      isNaN(fee) || !String(fee).match(/^[+]?([0-9]+(?:[\.][0-9]+)?|\.[0-9]+)$/)
        ? true
        : false
    );

    if (fee > 10000) fee = "10000.00";

    setFee(fee);
  };

  const SaveEntitySetting = (e) => {
    if (fieldError) return;

    if (e["name"] === "setting.conveniencefee" && !isNaN(e["value"]))
      e["value"] = parseFloat(e["value"]).toFixed(2);

    settingsService
      .updateSetting(entityID, { name: e["name"], value: e["value"] })
      .catch(() => {
        enqueueSnackbar("Error saving convenience fee setting", {
          variant: "error",
          tag: "ErrorSavingConvenienceFeeSetting",
        });
      });
  };

  return (
    <ConvenienceFeeInput
      fieldError={fieldError}
      existingFee={fee}
      feeChanged={feeChanged}
      SaveEntitySetting={SaveEntitySetting}
      toggleEnable={toggleEnable}
      enabledToggle={enabledToggle}
      toggleCharge={toggleCharge}
      chargeOnZeroToggle={chargeOnZeroToggle}
      mobilePayEnabled={mobilePayEnabled}
      toggleMobileFeeEnabled={toggleMobileFeeEnabled}
    />
  );
};

export default ConvenienceFee;
