/* eslint-disable no-mixed-spaces-and-tabs */
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    maxWidth: "400px",
    minWidth: "400px",
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
    },
  },
  detailContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  cssGridContainer: {
    display: "flex",
    flexGrow: 1
  },
  cssGrid: {
    flexGrow: 1,
    display: "grid",
    margin: theme.spacing(1),
    gridTemplateRows: "50px 1rem 1fr 1rem 50px"
  }
}));
