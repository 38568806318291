import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  splitBtn: {
    marginBottom: theme.spacing(2),
  },
  reasonBox: {},
  errorTxt: {
    color: theme.palette.error?.main,
    maxWidth: "200px",
  },
}));
