import React, {useEffect, useState} from "react";
import {Container, Typography} from "@material-ui/core"
import PropTypes from "prop-types";
import {useStyles} from "./styles";
import SquareChip from "../../../../SquareChip";
import LPREye from "../LPREye";
import DetailsValueRow from "../DetailsValueRow";
import useCancellationToken from "../../../../../hooks/useCancellationToken";
import {useEnqueueSnackbar} from "../../../../../hooks/useEnqueueSnackbar";
import InventoryService from "../../../../../services/InventoryService";
import apiClient from "../../../../../auth/apiClient";
import Skeleton from "@material-ui/lab/Skeleton";
import ContractService from "../../../../../services/ContractService";
import CredentialStatusChange from "../Form/CredentialStatusChange";
import clsx from "clsx";
import useHasPermissions from "../../../../../hooks/useHasPermissions";
import {useInventoryDrawerFormContext} from "../Form/InventoryDrawerFormProvider";

const inventoryService = new InventoryService(apiClient);
const contractService = new ContractService(apiClient);

const AccessHolderDetails = ({contextID}) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [loading, setLoading] = useState(false);
  const [accessHolderDetails, setAccessHolderDetails] = useState(null);
  const {hasPermissions} = useHasPermissions();
  const inventoryEditPermissions = hasPermissions(["inventory.edit"]);
  const {
    state: {canSave, saveClicked, status, reason},
    actions: {saveSuccess, setDefaultStatus}
  } = useInventoryDrawerFormContext();

  useEffect(() => {
    if (contextID) {
      executeDetailsQuery();
    }
  }, [contextID])

  const {execute: executeDetailsQuery} = useCancellationToken({
    func: async ({cancelToken}) => {
      setLoading(true);
      const res = await inventoryService.getAccessHolderDetails(contextID, cancelToken);
      setLoading(false);
      setAccessHolderDetails(res.data);
      setDefaultStatus(res.data.status.toLowerCase());
    },
    errHandleFunc: () => {
      setLoading(false);
      enqueueSnackbar("Failed to load details", {
        variant: "error",
        tag: "FailedToLoadDetails",
      });
    },
  });
  useEffect(() => {
    if (saveClicked && canSave) {
      handleAccessHolderStatusChange(status, reason);
    }
  }, [saveClicked]);

  const handleAccessHolderStatusChange = async (status, reason) => {
    try {
      await contractService.updateAccessHolderStatus(contextID, {
        status: status,
        reason: reason
      });
      setAccessHolderDetails({...accessHolderDetails, status: status})
      saveSuccess(status)
    } catch {
      enqueueSnackbar("Failed to update status", {
        variant: "error",
        tag: "FailedToUpdateAccessHolderStatus"
      });
    }
  };

  return <>
    {loading ?
      <Container>
        <Skeleton className={classes.skeleton} variant="rect" height="2rem" animation="wave"/>
        <Skeleton className={classes.skeleton} variant="rect" height="2rem" animation="wave"/>
        <Skeleton className={classes.skeleton} variant="rect" height="2rem" animation="wave"/>
      </Container>
      :
      <>
        {accessHolderDetails !== null && (
          <>
            <DetailsValueRow title="Status">
              {inventoryEditPermissions && accessHolderDetails.status ? (
                <CredentialStatusChange/>
              ) : (
                <Typography variant="h6" data-value={accessHolderDetails.status}>
                  {accessHolderDetails.status}
                </Typography>
              )}
            </DetailsValueRow>
            <DetailsValueRow title="Access Holder" thin={true} titleSize={6} valueSize={6}>
              <Typography>{accessHolderDetails?.firstName} {accessHolderDetails?.lastName}</Typography>
            </DetailsValueRow>
            <DetailsValueRow title="Credential" thin={true}>
              <Typography>{accessHolderDetails?.credentialReference}</Typography>
            </DetailsValueRow>

            {accessHolderDetails.plate && (
              <DetailsValueRow title="Plate" thin={true}> <span>
                <SquareChip mode="warning" text={accessHolderDetails.plate.read}/>
                {
                  accessHolderDetails.plate.hasImages &&
                  <LPREye lprPlateText={accessHolderDetails.plate.read} lprID={accessHolderDetails.plate.lprId}/>
                }
              </span>
              </DetailsValueRow>
            )}
          </>
        )}
      </>
    }
  </>
    ;
};

AccessHolderDetails.defaultProps = {
  contextID: null
};
AccessHolderDetails.propTypes = {
  contextID: PropTypes.string
};

export default AccessHolderDetails;
