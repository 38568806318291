/* eslint-disable no-mixed-spaces-and-tabs */
import {darken, lighten, makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
  },
	clickable: {
		cursor: "pointer",
		'&:hover': {
			backgroundColor: lighten(theme.palette.amano.base.secondary.main, 0.1)
		}
	},
  plateText:{
    border: 'thin solid gray',
    textAlign:"center",
    borderRadius:3,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding:"4px 6px 4px 6px",
		fontFamily: `"Lucida Console","Courier New","monospace"`,
  },
	plateEntry:{
		display: "flex",
		alignItems: "baseline"
	},
	confidence: {
		color: theme.palette.text.disabled,
		marginRight: theme.spacing(1),
		fontSize: "smaller",
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
}));
