/* eslint-disable no-mixed-spaces-and-tabs */
import React, {useEffect, useState,} from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";

import clsx from "clsx";
import {useStyles} from "./index.styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCameraCctv, faFilmSlash, faImage} from "@fortawesome/pro-duotone-svg-icons";
import LPRImage from "./LPRImage";

const LPRImageDialog = ({lprID, lprPlateText, toOpen, onClose}) => {
  const classes = useStyles();
  const [context, setContext] = useState("context");
  const [open, setOpen] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(true);

  useEffect(() => {
    setImageRemoved(lprPlateText?.toUpperCase() === "REMOVED");
  }, [lprPlateText]);

  useEffect(() => {
    if (toOpen) {
      setOpen(true);
    }
  }, [toOpen]);

  const handleClose = (e) => {
    setOpen(false);
    setContext("context");
    onClose();
  }

  return (
    <>
      <Dialog className={clsx(["lpr-image-container"])}
              fullScreen
              fullWidth
              keepMounted
              open={open}
              onClick={(e) => e.stopPropagation()}
              onClose={handleClose}
      >
        <DialogTitle>
          <>
            <span className={clsx(["title", classes.title, imageRemoved ? "dontshow" : ""])}>License Plate</span>
            <div alt="Plate Text" title="Plate Text"
                 className={clsx(["title-plate-text", classes.titlePlateText, imageRemoved ? "dontshow" : ""])}>
              {lprPlateText}
            </div>
          </>
        </DialogTitle>
        <DialogContent>
          {imageRemoved ? (
              <>
                <div className={clsx(["image-wrapper", classes.removedImageWrapper])}>
                  <div className={clsx(["image-removed", classes.imageRemoved])}>
                    <div>IMAGE HAS</div>
                    <FontAwesomeIcon
                      className={clsx(["animate__animated", "animate__flipInY", classes.removedIcon])}
                      icon={faFilmSlash} size="4x"/>
                    <div>BEEN REMOVED</div>
                  </div>
                </div>

              </>
            ) :
            (
              <>
                {open && (
                  <LPRImage lprID={lprID} lprPlateText={lprPlateText}
                            forCaptureImage={context !== "context"}></LPRImage>
                )}
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <div
            className={clsx(["image-toggle-bar", classes.thumbnailBar, (lprPlateText?.toUpperCase() === "REMOVED") ? "dontshow" : ""])}>
            <div className={clsx(["btn-context-image", classes.thumbnail, (context === "context") ? "selected" : ""])}
                 alt="Context Image" title="Context Image" onClick={(e) => setContext("context")}>
              <FontAwesomeIcon size="2x" icon={faImage}/>
            </div>
            <div className={clsx(["btn-capture-image", classes.thumbnail, (context === "capture") ? "selected" : ""])}
                 alt="Capture Image" title="Capture Image" onClick={(e) => setContext("capture")}>
              <FontAwesomeIcon size="2x" icon={faCameraCctv}/>
            </div>
          </div>
          <div style={{flex: '1 0 0'}}/>
          <Button className={clsx(["button", "close"])} aria-label="close-button" name="close" variant="contained"
                  onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
LPRImageDialog.defaultProps = {
  lprID: null,
  lprPlateText: null,
  toOpen: false,
  onClose: () => {
  },
};
LPRImageDialog.propTypes = {
  lprID: PropTypes.number,
  lprPlateText: PropTypes.string,
  toOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default LPRImageDialog;
