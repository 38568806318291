/* eslint-disable no-mixed-spaces-and-tabs */
import { darken, lighten, makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
		paddingLeft:10,
		paddingTop:5,
  },
  title: {
    color:theme.palette.primary,
  },
	header: {
	},
	graph: {
		borderBottom: 'thin solid gray',
		paddingLeft: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		},
	},
	tableContainer: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		justifyContent: 'flex-end'
	},

	dividerPad:{
		marginLeft:"4px",
		marginRight:"4px",
	},
	searchContainer:{

	},
	skeletonWrapper:{
		width:"96%",
		marginTop:50,
		textAlign:"center",
		"&.skeleton":{
			color:theme.palette.amano.base.primary.main,
			backgroundColor:theme.palette.amano.base.primary.main,
		}

	},
	graphContainer:{
	},
	resultTable: {
		margin: '10px 25px 25px 25px !important'
	},
	pagination:{
		marginTop: '10px'
	},
	noResults: {
		marginTop:50,
		textAlign:"center",
		width:"98%",
		"& .no-results-text":{
			border:"1px solid",
			borderColor:darken(theme.palette.amano.warnings.fire,.3),
			padding:"8px 92px 8px 92px",
			backgroundColor: theme.palette.background.paper,
			fontSize:"14pt",
			fontStyle:"italic",
			fontWeight:"bold",
			color:darken(theme.palette.amano.warnings.fire,.2),
			opacity:0.7,
			borderRadius:3,
			boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
		},
	},
	resultsDivider:{
		marginTop:15,
		marginBottom:10,
		marginRight:8,
	},
	label : {
		minWidth:80,
		display:"inline-block",
	},
}));
