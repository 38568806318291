/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import moment from "moment-timezone";

import LPRRowImage from './LPRRowImage'
import CredentialInventoryDetailDrawer from '../CredentialInventoryDetailDrawer'

import { FindFacilityOfEntity } from "../../../../state/slices/entityScope";

import { useStyles } from "./styles";
import '../../../ResponsiveTable/_responsive.table.css';
import useCurrentFacilityTimezone from "../../../../hooks/useCurrentFacilityTimezone";
import InventoryDrawerFormProvider from "../CredentialInventoryDetailDrawer/Form/InventoryDrawerFormProvider";

const CredentialInventoryTable = ({ results, credentialType, onSuccessfulStatusChange }) => {

  const classes = useStyles();

  const [selectedCredential, setSelectedCredential] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {timeZone} = useCurrentFacilityTimezone();

  const availableEntities = useSelector((state) => state.entityScope?.available);

  const findNearestFacilityName = (_entityID) => {
    return FindFacilityOfEntity(availableEntities, _entityID)?.name
  };

  const onRowClick = (credential) => {
    setSelectedCredential(credential);
    setDrawerOpen(true);
  }

  const onDrawerClose = (successfulStatusChange) => {
    setSelectedCredential(null);
    setDrawerOpen(false)
    if(successfulStatusChange){
      onSuccessfulStatusChange();
    }
  }

  return (
    <>
      <table className={clsx([classes.resultTable, "a1-responsive"])}>
        <thead className={clsx([classes.thHeader])}>
          <tr>
            <th className={clsx([classes.resultHeader])}> {credentialType == 1 ? 'Credential' : 'Name'} </th>
            <th className={clsx([classes.resultHeader])}>Facility</th>
            <th className={clsx([classes.resultHeader])}>Entrance Time<span className={classes.ageWrapper}>(Age)</span></th>
          </tr>
        </thead>
        <tbody>
          {results?.collection?.map((result, index) => (
            <tr key={result.contextID} 
                className={clsx(['clickable-row'])} 
                onClick={() => onRowClick(result)} data-selected={result.contextID === selectedCredential?.contextID} 
                data-testid="clickable-row">
              <td
                data-column="Name/Credential"
                className={clsx([classes.nameColumn])}
              >
                <div className={clsx([classes.credentialFlex])}>
                  <span className={clsx([classes.name])}>
                    {result.contextValue}
                  </span>
                  {result.hasImages && (
                    <div className={classes.lpWrapper}>
                      <LPRRowImage
                        enabled={result.hasImages}
                        lprPlateText={result.licensePlate ? result.licensePlate : "No Plate Found"}
                        lprID={result.lprId}
                        lprConfidence={result.lprConfidence}
                      />
                    </div>
                  )}
                </div>
              </td>
              <td data-column="Facility" className={clsx([classes.column])}>
                {findNearestFacilityName(result.entityID)}
              </td>
              <td
                data-column="Entrance Time"
                className={clsx([classes.column])}
              >
                {result.entranceTime && (
                  <>
                    <span>{moment.utc(result.entranceTime).tz(timeZone).format("MM/DD/YYYY h:mm A")}</span>
                    <span className={classes.ageWrapper}>({moment.utc(result.entranceTime).tz(timeZone).fromNow()})</span>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <InventoryDrawerFormProvider>
        <CredentialInventoryDetailDrawer credential={selectedCredential} type={credentialType} open={drawerOpen} onClose={(success) => onDrawerClose(success)} />
      </InventoryDrawerFormProvider>
    </>
  );
};

export default CredentialInventoryTable;
