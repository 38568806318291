/* eslint-disable no-mixed-spaces-and-tabs */
import { darken, lighten, makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  resultTable: {
    margin: '10px 25px 25px 25px !important'
  },
  resultHeader: {
    backgroundColor: theme.palette.header,
    color: theme.palette.amano.base.main,
  },
  lpWrapper: {
    float: 'right',
  },
  name: {
    alignSelf: 'center'
  },
  ageWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginLeft: 0,
    },
    marginLeft: 5,
    color: theme.palette.amano.base.secondary.main,
  },
  clickableRow: {
    cursor: 'pointer'
  },
	confidence: {
		float: 'right'
	},
	credentialFlex: {
		display: "flex",
		justifyContent: "space-between",
		flexWrap: "wrap"
	}
}));