import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  videoContent: {
    display: "inline-block",
    position: "relative",
    border: "1px solid black",
    width: "320px",
    height: "240px",
  },
  video: {
    /* the video element */
    width: "320px",
    height: "240px",
    [theme.breakpoints.between('sm', 'xl')]: {  //mirror it naturally except XS, which means phone
      transform: "rotateY(180deg)",             //back-camera, which then feels weird.
      WebkitTransform: "rotateY(180deg)",
      MozTransformOrigin: "rotateY(180deg)",      
    },    
  },
  overlayMessage: {
    /*overlay message to display on top*/
    color: "#fff",
    fontFamily: "sans-serif",
    position: "absolute",
    top: "15px",
    left: "30px",
    fontWeight: "bold",
    fontSize: "10pt",
    textAlign: "center",
    padding: "0",
    lineHeight: "20px",
    height: "20px",
    width: "250px",
    border: "2px solid black",
    backgroundColor: "#000",
    opacity: ".5",
  },
  overlayFocus: {
    /*square to put barcoded*/
    backgroundColor: "transparent",
    position: "absolute",
    border: "4px solid orange",
    top: "60px",
    left: "110px",
    height: "100px",
    width: "100px",
    minHeight: "100px",
    maxHeight: "100px",
    transition: "background-color 30ms linear",
    WebkitTransition: "background-color 30ms linear",
  },
  overlayFocusDisabled: {
    /*square to put barcoded*/
    backgroundColor: "transparent",
    position: "absolute",
    border: "4px solid gray",
    top: "60px",
    left: "110px",
    height: "100px",
    width: "100px",
    minHeight: "100px",
    maxHeight: "100px",
    transition: "background-color 30ms linear",
    WebkitTransition: "background-color 30ms linear",
  },
  overlayFocusFlash: {
    /*what to transition to on a read hit*/
    borderColor: "red !important",
    backgroundColor: "#fff",
    transition: "background-color 30ms linear",
    opacity: ".5",
  },
}));