/* eslint-disable no-mixed-spaces-and-tabs */
import { darken, lighten, makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
	root: {
	},

	icon: {
		verticalAlign: "bottom",
		marginLeft: theme.spacing(1),
		'&:hover': {
			cursor: "pointer"
		}
	},
}));
