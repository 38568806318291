import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useStyles } from "./styles";
import { Doughnut, Chart } from "react-chartjs-2";
import { blue, grey } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import clsx from "clsx";

const TodaysTickets = ({ ticketData }) => {
  const classes = useStyles();
  const history = useHistory();
  const { hasPermissions } = useHasPermissions();
  const ReportPermission = hasPermissions(["RevenueTransaction"]);
  let colors = [blue[500], blue[700], blue[900]];
  let labels = ["Exited", "Unpaid", "Over 1 Day"];

  let counts = [
    ticketData?.exited == null ? 0 : ticketData?.exited,
    ticketData?.unpaid == null ? 0 : ticketData?.unpaid,
    ticketData?.over1Day == null ? 0 : ticketData?.over1Day,
  ];

  const handleReportClick = () => {
    history.push(`/reports/RevenueTransaction`);
  };

  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: colors,
        data: counts,
      },
    ],
  };

  const options = {
    cutoutPercentage: 60,
    responsive: false,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  };

  return (
    <>
      <Grid
        container
        style={{ height: "100%" }}
        className={clsx("todays-tickets")}
      >
        <Grid item xs={12} style={{ maxHeight: "50px" }}>
          <Typography
            className={clsx("title", classes.title)}
            color="primary"
            data-id="title"
          >
            {"Today's Tickets"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          className={classes.tableGrid}
          style={{ margin: "10px" }}
        >
          <Table size="small" data-id="TodaysTicketsTable">
            <TableBody style={{ marginLeft: "3%" }}>
              <TableRow
                key={labels[0]}
                className={clsx(`${labels[0]}-table-row`)}
                data-id={labels[0].replace(/\s+/g, "") + "_row"}
              >
                <TableCell>
                  <FiberManualRecordIcon
                    style={{ color: colors[0] }}
                    data-id="coloredDot"
                    className={clsx(`${labels[0]}-colored-dot`)}
                  />
                </TableCell>
                <TableCell
                  data-id={labels[0]}
                  className={clsx(`${labels[0]}-description`)}
                >
                  {labels[0]}
                </TableCell>
                <TableCell
                  data-id={labels[0] + "_total"}
                  className={clsx(`${labels[0]}-cell`)}
                  data-value={counts[0]}
                  data-testid="exited-tickets"
                >
                  {counts[0]}
                </TableCell>
              </TableRow>
              <TableRow
                key={labels[1]}
                className={clsx(`${labels[1]}-table-row`)}
                data-id={labels[1].replace(/\s+/g, "") + "_row"}
              >
                <TableCell>
                  <FiberManualRecordIcon
                    style={{ color: colors[1] }}
                    data-id="coloredDot"
                    className={clsx(`${labels[1]}-colored-dot`)}
                  />
                </TableCell>
                <TableCell
                  data-id={labels[1]}
                  className={clsx(`${labels[1]}-description`)}
                >
                  {labels[1]}
                </TableCell>
                <TableCell
                  data-id={labels[1] + "_total"}
                  className={clsx(`${labels[1]}-cell`)}
                  data-value={counts[1]}
                  data-testid="unpaid-tickets"
                >
                  {counts[1]}
                </TableCell>
              </TableRow>
              <TableRow
                key={labels[2]}
                className={clsx(`${labels[2]}-table-row`)}
                data-id={labels[2].replace(/\s+/g, "") + "_row"}
              >
                <TableCell>
                  <FiberManualRecordIcon
                    style={{ color: colors[2] }}
                    data-id="coloredDot"
                    className={clsx(`${labels[2]}-colored-dot`)}
                  />
                </TableCell>
                <TableCell
                  data-id={labels[2]}
                  className={clsx(`${labels[2]}-description`)}
                >
                  {labels[2]}
                </TableCell>
                <TableCell
                  data-id={labels[2] + "_total"}
                  className={clsx(`${labels[2]}-cell`)}
                  data-value={counts[2]}
                  data-testid="over1Day-tickets"
                >
                  {counts[2]}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className={clsx("doughtnut-chart", classes.chartGrid)}
          style={{ margin: "10px" }}
        >
          <Doughnut data-id="doughnutChart" data={data} options={options} />
        </Grid>
        <Grid item xs={12} className={classes.reportText}>
          {ReportPermission && (
            <Link
              data-id="RevenueTransactionLink"
              onClick={handleReportClick}
              className={clsx("report-link", classes.pointer)}
            >
              Revenue Transactions Report
            </Link>
          )}
        </Grid>
      </Grid>
    </>
  );
};

TodaysTickets.propTypes = {
  ticketData: PropTypes.shape({
    exited: PropTypes.number,
    unpaid: PropTypes.number,
    over1Day: PropTypes.number,
  }),
};

export default TodaysTickets;
